.circle-background {
  width: 100%;
  /* margin: 1%; */
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 10px solid white;
  position: relative;
}

.circle-background::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.hello {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.no-padding-right {
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 9px;
  padding-bottom: 10px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  footer {
    position: absolute !important;
    bottom: 0 !important;
  }
  .col-md-3-5 {
    width: 30%;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  footer {
    position: absolute;
    bottom: 0;
  }
  .col-md-3-5 {
    width: 30%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  footer {
    position: absolute;
    bottom: 0;
  }
  .col-md-3-5 {
    width: 30%;
  }
}
